//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
//@import "magesparrow-blank/Amasty_Orderattr/web/css/module";
//------------------------------------//

// variables
$amcform__maxwidth: 700px;


// reset styles
.amcform-page-wrap input[type=radio],
.filter-options input[type=radio],
.radio input[type=radio],
input[type=radio].radio {
    margin-bottom: 1rem;
    vertical-align: top;
}

.amcform-page-wrap input[type=checkbox]+label,
.choice input[type=checkbox]+label,
.filter-options input[type=checkbox]+label {
    margin-bottom: 0;
}

.amcform-page-wrap input[type=checkbox]+label:before,
.choice input[type=checkbox]+label:before,
.filter-options input[type=checkbox]+label:before {
    margin-right: 1rem;
}

body {
    ol.amcform-page-titles,
    ul.amcform-page-titles {
        padding: 0;
        list-style-type: none;
    }
    .amform-form {
        max-width: $amcform__maxwidth;
        margin-left: auto;
        margin-right: auto;

        .amcform-title-wrap:before,
        .amcform-title-wrap:after {
            right: 52px;
            @include respond-to-up(md) {
                right: 32px;
            }
        }

        .amcform-title-wrap.-done + .amcform-title-wrap:before {
            background-color: map-get($theme-colors, 'secondary');
        }

        .amcform-multi-page .amcform-title-wrap {
            max-width: 95px;
            flex-grow: unset;

            @include respond-to-up(md) {
                max-width: none;
            }

            a.amcform-title {
                text-decoration: none;
            }

            .amcform-step {
                background-color: map-get($theme-colors, 'primary');
                border-color: map-get($theme-colors, 'primary');
                border-width: 2px;
            }

            &.ui-state-active {
                .amcform-step {
                    background-color: map-get($theme-colors, 'primary');
                    border-color: map-get($theme-colors, 'dark');
                }
                .amcform-label {
                    color: map-get($theme-colors, 'primary');
                }
            }

            &.-disabled {
                .amcform-step {
                    background-color: map-get($theme-colors, 'light');
                }
            }

            &.-done,
            &.-active {
                .amcform-step {
                    background-color: map-get($theme-colors, 'secondary');
                }
            }
        }
    }

    .amform-form input[type="number"] {
        width: 100%;
        max-width: none;
    }

    .amcform-gdpr {
        .label {
            display: block;
        }
        .label a {
            margin-left: 5px;
        }
    }
}

// global styles for datepicker and calendar
body .ui-datepicker {
    box-sizing: border-box;
    opacity: 0.98;
    padding: 23px 20px;
    width: auto;

    .ui-datepicker-header {
        padding: 0 0 10px;
        position: relative;
        z-index: 1;

        .ui-datepicker-title {
            line-height: 1.8em;
            margin: 0 1.5em;
            text-align: center;

            .ui-datepicker-month {
                margin-right: 10px;
                width: 47%;
            }

            .ui-datepicker-year {
                width: 47%;
            }

            select {
                padding: 0 !important;
                background-color: transparent;
                border: 0;
                margin: 0;
            }
        }

        .ui-datepicker-prev, .ui-datepicker-next {
            cursor: pointer;
            line-height: 2rem;
            position: absolute;
            top: 7px;
            color: inherit;
            width: 15px;
            height: 18px;

            &:before {
                color: currentColor;
                font-size: 34px;
                position: absolute;
            }

            span {
                display: none;
            }
        }

        .ui-datepicker-prev {
            @include ms-icon('chevron-left');
            left: 0;
        }

        .ui-datepicker-next {
            @include ms-icon('chevron-right');
            right: 0;
        }

    }

    table.ui-datepicker-calendar {
        background: #ffffff;
        border: 0;
        border-collapse: collapse;
        position: relative;
        z-index: 1;
        width: 100%;
    }
}

body .ui-datepicker-trigger {
    @include ms-icon('calendar');
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

body .amform-form input[name="date"] {
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    border-width: $input-border-width;
    border-style: solid;
    padding: $input-padding-y $input-padding-x;
}
// end styles for datepicker and calendar

// fix for width issues on flex containers - register account page */
body .amform-form {
    width: 100%;
}
