@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.amcform-page-wrap input[type=radio],
.filter-options input[type=radio],
.radio input[type=radio],
input[type=radio].radio {
  margin-bottom: 1rem;
  vertical-align: top;
}

.amcform-page-wrap input[type=checkbox] + label,
.choice input[type=checkbox] + label,
.filter-options input[type=checkbox] + label {
  margin-bottom: 0;
}

.amcform-page-wrap input[type=checkbox] + label:before,
.choice input[type=checkbox] + label:before,
.filter-options input[type=checkbox] + label:before {
  margin-right: 1rem;
}

body ol.amcform-page-titles,
body ul.amcform-page-titles {
  padding: 0;
  list-style-type: none;
}

body .amform-form {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

body .amform-form .amcform-title-wrap:before,
body .amform-form .amcform-title-wrap:after {
  right: 52px;
}

@media screen and (min-width: 768px) {
  body .amform-form .amcform-title-wrap:before,
  body .amform-form .amcform-title-wrap:after {
    right: 32px;
  }
}

body .amform-form .amcform-title-wrap.-done + .amcform-title-wrap:before {
  background-color: #D7D38A;
}

body .amform-form .amcform-multi-page .amcform-title-wrap {
  max-width: 95px;
  flex-grow: unset;
}

@media screen and (min-width: 768px) {
  body .amform-form .amcform-multi-page .amcform-title-wrap {
    max-width: none;
  }
}

body .amform-form .amcform-multi-page .amcform-title-wrap a.amcform-title {
  text-decoration: none;
}

body .amform-form .amcform-multi-page .amcform-title-wrap .amcform-step {
  background-color: #b0aa00;
  border-color: #b0aa00;
  border-width: 2px;
}

body .amform-form .amcform-multi-page .amcform-title-wrap.ui-state-active .amcform-step {
  background-color: #b0aa00;
  border-color: #000;
}

body .amform-form .amcform-multi-page .amcform-title-wrap.ui-state-active .amcform-label {
  color: #b0aa00;
}

body .amform-form .amcform-multi-page .amcform-title-wrap.-disabled .amcform-step {
  background-color: #eee;
}

body .amform-form .amcform-multi-page .amcform-title-wrap.-done .amcform-step, body .amform-form .amcform-multi-page .amcform-title-wrap.-active .amcform-step {
  background-color: #D7D38A;
}

body .amform-form input[type="number"] {
  width: 100%;
  max-width: none;
}

body .amcform-gdpr .label {
  display: block;
}

body .amcform-gdpr .label a {
  margin-left: 5px;
}

body .ui-datepicker {
  box-sizing: border-box;
  opacity: 0.98;
  padding: 23px 20px;
  width: auto;
}

body .ui-datepicker .ui-datepicker-header {
  padding: 0 0 10px;
  position: relative;
  z-index: 1;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  line-height: 1.8em;
  margin: 0 1.5em;
  text-align: center;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month {
  margin-right: 10px;
  width: 47%;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-year {
  width: 47%;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
  padding: 0 !important;
  background-color: transparent;
  border: 0;
  margin: 0;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  line-height: 2rem;
  position: absolute;
  top: 7px;
  color: inherit;
  width: 15px;
  height: 18px;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before, body .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  color: currentColor;
  font-size: 34px;
  position: absolute;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span, body .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
  display: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  left: 0;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  right: 0;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .ui-datepicker table.ui-datepicker-calendar {
  background: #ffffff;
  border: 0;
  border-collapse: collapse;
  position: relative;
  z-index: 1;
  width: 100%;
}

body .ui-datepicker-trigger {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

body .ui-datepicker-trigger:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .amform-form input[name="date"] {
  border-radius: 0px;
  border-color: #000;
  border-width: 2px;
  border-style: solid;
  padding: 0.75rem 1.5rem;
}

body .amform-form {
  width: 100%;
}
